<script lang="ts">
    import '$src/app.postcss';
    import { onMount, tick } from 'svelte';
    import { Loader } from '@allibee/components/common';
    import { Toast, Modal } from '@allibee/components/basic';
    import { browser } from '$app/environment';
    import { afterNavigate, beforeNavigate, goto, replaceState } from '$app/navigation';
    import { page } from '$app/stores';
    import { blur } from 'svelte/transition';
    import { cubicIn } from 'svelte/easing';
    import { modal } from '$stores/modalStore';
    import ResizeObserver from 'resize-observer-polyfill';
    import { isEqual, isEmpty, debounce } from 'lodash-es';
    import { isLoadingOverlay } from '$stores/loadingStore';
    import { clearAbortedOverlayCount, hideLoadingOverlay, showLoadingOverlay } from '$lib/utils';
    import DevHelpWidget from './DevHelpWidget.svelte';
    import 'core-js/proposals/change-array-by-copy'; // polyfill for https://github.com/tc39/proposal-change-array-by-copy'
    import 'core-js/modules/web.structured-clone.js'; // polyfill for structuredClone
    import 'core-js/proposals/relative-indexing-method';
    import { _ } from 'svelte-i18n';
    import { HeadTitle, PwaLoader } from '$lib/service';
    import { base } from '$app/paths';
    import { refreshToken } from '$stores/auth';
    import dayjs from 'dayjs';
    if (browser) window.ResizeObserver = ResizeObserver; // Polyfill

    export let data;

    // TODO: https://github.com/sveltejs/kit/issues/11956 이슈로 인한 임시 코드
    // shallow routing 으로 구현된 replaceState의 경우 history.state를 초기화 시키기는 하지만 동기화가 되지않는 문제가 있다.
    // 또한 페이지 로딩시 $page.state를 초기화해버리는 문제가 존재해서 onMount 단계에서는 history.state 의 값을 정확히 알아오지 못하는 문제가 발생한다.
    // afterNavigate 단계에서는 정상적인 $page.state 를 읽을 수 있다
    $: {
        if (browser && isEmpty($page.state) && !isEmpty(history.state['sveltekit:states'])) {
            goto('', { replaceState: true, state: history.state['sveltekit:states'] });
            setTimeout(() => {
                replaceState('', history.state['sveltekit:states']);
            });
        }
    }
    $: ({ pathname, session } = data);

    // 페이지 전환시 beforeNavigate.to 가 afterNavigate.to 와 항상 같지는 않다. 예를들어 라우팅 경로 하위의 디폴트 경로로 이동하게 되는 경우가 그렇다.
    let lastBeforeNavigateFrom;
    let lastBeforeNavigateTo;
    beforeNavigate(async ({ from, to, willUnload, type }) => {
        if (__DEBUG_LOADING_OVERLAY__) {
            console.groupCollapsed(`Navigate Root Layout ${from?.url.pathname} -> ${to?.url.pathname}`);
            console.log('beforeNavigate', { from, to, willUnload, type });
        }

        $modal.forEach(m => {
            if (m.type !== 'fullcustom') {
                modal.close(m.id, false);
            }
        });

        if (to?.route.id && (to?.route.id !== from?.route.id || !isEqual(to?.params, from?.params))) {
            lastBeforeNavigateFrom = from;
            lastBeforeNavigateTo = to;
            showLoadingOverlay();

            await tick(); // beforeNavigate의 경우 상위페이지부터 호출되기 때문에 다음 프레임으로 넘기기 위해 tick()을 사용한다.

            // 페이지 단위 beforeNavigate 이벤트에서 navigation을 취소하는 경우
            clearAbortedOverlayCount();
        }
    });
    afterNavigate(({ from, to, willUnload, type }) => {
        if (!from?.url || type === 'enter') {
            // 서비스 첫 진입시
        } else if (to?.route.id && (to?.route.id !== from?.route.id || !isEqual(to?.params, from?.params))) {
            hideLoadingOverlay();
        } else if (lastBeforeNavigateFrom && lastBeforeNavigateTo && from?.route.id === lastBeforeNavigateFrom.route.id && to?.route.id !== lastBeforeNavigateTo.route.id) {
            // 페이지 전환중에 페이지 전환을 취소하고 다른 페이지로 이동하는 경우
            lastBeforeNavigateFrom = undefined;
            lastBeforeNavigateTo = undefined;

            hideLoadingOverlay();
        }
        if (__DEBUG_LOADING_OVERLAY__) {
            console.log('afterNavigate', { from, to, willUnload, type });
            console.groupEnd();
        }
    });

    let modalWrapperRefs: HTMLDivElement[] = [];

    $: modalWrapperRefs.filter(Boolean).at(-1)?.querySelector('[tabindex="-1"][role="dialog"]')?.focus();

    onMount(() => {
        const handleRefreshOnUserAction = debounce(async () => {
            const lastRefreshTime = localStorage.getItem('lastRefreshTime');
            // 토큰 리프레시 조건 (1. 로그인 또는 마지막 리프레시를 수행한 시간(lastRefreshTime)이 존재하고, 2. 현재 세션이 존재하는 경우)
            if (!lastRefreshTime || !session) return;
            const now = dayjs();
            const msSinceRefresh = now.diff(dayjs(parseInt(lastRefreshTime, 10)), 'millisecond');

            // 마지막 리프레시로부터 600,000ms(10분) 이상 지났고, 3,600,000ms(60분) 미만인 경우에만 리프레시
            if (10 * 60 * 1000 <= msSinceRefresh && msSinceRefresh < 60 * 60 * 1000) {
                await refreshToken();
                localStorage.setItem('lastRefreshTime', `${Date.now()}`);
            }
        }, 1000);

        window.addEventListener('mousemove', handleRefreshOnUserAction, true);
        window.addEventListener('keydown', handleRefreshOnUserAction, true);

        return () => {
            window.removeEventListener('mousemove', handleRefreshOnUserAction, true);
            window.removeEventListener('keydown', handleRefreshOnUserAction, true);
        };
    });
</script>

<svelte:head>
    {#each $page.data.themeConfig.fontLinks as fontLink}
        <link rel="stylesheet" href="{base}{fontLink}" as="style" crossorigin="anonymous" />
    {/each}
    <link rel="icon" href={$page.data.themeConfig.faviconHref} />
</svelte:head>
<HeadTitle title={$page.data.title} />
<PwaLoader />

<!-- 페이지 전환중 로딩바 -->
<div>
    <!--
        sveltekit2 버전의 문제인지 원인은 정확히 모르겠지만 최상위 layout.svelte의 최상위 dom이 #if로 제어가 될때
        하이드레이션 동작에 문제가 발생하는듯하다. 그래서 최상위 dom은 항상 렌더링되도록 하고, 내부의 dom을 제어하도록 한다.
    -->
    {#if $isLoadingOverlay}
        <div class="fixed z-[110] flex h-screen w-screen items-center justify-center">
            <div class="flex h-12 w-12 items-center justify-center">
                <Loader />
            </div>
        </div>
    {/if}
</div>

<!-- 본문 -->
<div class="width-constraint h-0 min-h-screen w-full min-w-[80rem]" class:blur={$isLoadingOverlay}>
    {#key pathname}
        <div class="h-full" in:blur={{ easing: cubicIn, duration: 300 }}>
            <slot />
        </div>
    {/key}

    <!-- global functional modal -->
    {#each $modal as { Component, props, modalProps, id, type }, i (id)}
        <div bind:this={modalWrapperRefs[i]}>
            {#if type === 'fullcustom'}
                <svelte:component this={Component} on:close={e => modal.close(id, e.detail)} on:confirm={e => modal.close(id, e.detail)} on:cancel={() => modal.close(id, false)} {...props} />
            {:else}
                <Modal open {...modalProps} on:outroend={e => modal.close(id, ['confirm', 'prompt', 'custom'].includes(type) ? false : true)} zIndexBackdrop={40 + i * 10} zIndexModal={45 + i * 10}>
                    <svelte:component
                        this={Component}
                        on:confirm={e => modal.close(id, e.detail)}
                        on:cancel={() => modal.close(id, false)}
                        confirmText={$_('lib.utils.modal.confirm')}
                        cancelText={$_('lib.utils.modal.cancel')}
                        {...props}
                    />
                </Modal>
            {/if}
        </div>
    {/each}
</div>
<!-- global toast -->
<Toast />

<!-- 개발자 도움 위젯 -->
{#if __IS_DEV_SERVER_ENV__}
    <DevHelpWidget />
{/if}
